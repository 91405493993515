<template>
  <div>
    <div>
      <action-header
          :is-loading="!shippingPlan"
          :actions-model="headerModel"
          :page="page"
      />

      <div
          v-if="hasDeliveryServiceStatus && shippingPlan.state === 'error'"
          class="q-pa-md text-center bg-red-5 rounded"
      >
        <strong>{{ deliveryRequest.deliveryServiceStatus }}</strong>
      </div>

      <div
          v-if="hasDeliveryServiceStatus && shippingPlan.state !== 'error'"
          class="q-pa-md text-center bg-amber-6 rounded"
      >
        <strong>{{ deliveryRequest.deliveryServiceStatus }}</strong>
      </div>

      <div
          v-if="shippingPlan && shippingPlan.stateDescription && shippingPlan.state === 'error'"
          class="q-pa-md text-center bg-red-5 rounded"
      >
        <strong>{{ shippingPlan.stateDescription }}</strong>
      </div>

      <div
          v-if="shippingPlan && shippingPlan.stateDescription && shippingPlan.state !== 'error'"
          class="q-pa-md text-center bg-amber-6 rounded"
      >
        <strong>{{ shippingPlan.stateDescription }}</strong>
      </div>


      <div
          v-if="hasShippingPlanErrors"
          class="q-pa-md text-center bg-red-5 rounded"
      >
        <strong>{{ shippingPlan.shippingPlan }}</strong>
      </div>

      <div class="q-pa-md">
        <q-legend class="q-mb-sm" :label="$t('Order Contents')"/>

        <shipping-plan-content
            v-if="shippingPlan"
            ref="orderProducts"
            :is-shippingPlan-loading="!shippingPlan"
            :shippingPlan="shippingPlan"
            :disabled="isDisabledFields"
            :shop="shippingPlan && shippingPlan.shop"
            :warehouse="shippingPlan && shippingPlan.warehouse"
            @warehouse-change="handleWarehouseChange"
            @shop-change="handleShopChange"
            @update-order="updateShippingPlan"
            @update-source="updateShippingPlanSource"
        />

        <q-separator class="q-my-md"/>

        <div v-if="this.shippingPlan &&this.shippingPlan.raw && this.shippingPlan.raw.ShipToAddress">
          <div class="row items-center q-mb-md" >
            <q-legend
                :label="
              $t('Ship To Address')
            "
            />

            <q-btn-group class="q-ml-sm">
              <q-btn
                  v-if="!disabledDomestic"
                  size="sm"
                  :text-color="isInternational ? 'dark' : 'white'"
                  :color="isInternational ? 'grey-3' : 'light-blue-9'"
                  :icon="isInternational ? undefined : 'done'"
                  :disable="false"
                  :label="$t('Domestic')"
                  @click="isInternational = false"
              />

              <q-btn
                  size="sm"
                  :text-color="isInternational ? 'white' : 'dark'"
                  :color="isInternational ? 'light-blue-9' : 'grey-3'"
                  :icon="isInternational ? 'done' : undefined"
                  :disable="false"
                  :label="$t('International')"
                  @click="isInternational = true"
              />
            </q-btn-group>

            <q-btn
                :label="$t('Show pick up points')"
                color="dark"
                class="q-ml-sm"
                size="sm"
                text-color="white"
                :disable="isDisabledFields"
                @click="handleOpenMap"
            />
          </div>

          <c-address
              ref="address"
              :is-loading="!this.shippingPlan.raw.ShipToAddress"
              :type="addressType"
              :disabled="true"
              :model="this.shippingPlan.raw.ShipToAddress || {}"
              @address="updateRecipientAddress"
          >
            <base-address
                :is-loading="!this.shippingPlan.raw.ShipToAddress"
                :address="this.shippingPlan.raw.ShipToAddress || undefined"
                :disabled="isDisabledFields"
                @change="updateRecipientAddress"
            />
          </c-address>
        </div>

      </div>

      <sticky-bottom-header
          :is-loading="isSaveLoading"
          :is-active="hasShippingPlanChange"
          @back="handleDiscard"
          @save="save"
      />
    </div>


    <q-dialog v-model="historyModal">
      <q-card style="min-width: 65vw">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('History') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>

        <q-card-section v-if="shippingPlan && shippingPlan.id">
          <history
              :id="shippingPlan.id"
              :entity="'Orderadmin\\Integrations\\Amazon\\Entity\\FBA\\ShippingPlan\\Item'"
          />
        </q-card-section>

        <q-card-section v-else class="alert alert-warning">
          {{ $t('Order not exist!') }}
        </q-card-section>
      </q-card>
    </q-dialog>

    <order-services-modal ref="orderServices"/>

    <chat-modal ref="chatModal"/>

    <document-template-modal
        ref="documentModal"
        :entity-class="'Orderadmin\\Integrations\\Amazon\\Entity\\FBA\\ShippingPlan\\Item'"
    />

    <eav-modal ref="eavModal" @submit="handleEAVSubmit">
      <form-builder :schema="schema"/>
    </eav-modal>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader'
import TableMixin from '../../components/global/TableMixin'
import History from '../../components/history/history'
import NInteractions from '../../components/notifications/Interactions'
import BaseAddress from './../../components/clients/BaseAddress'
import DeliveryRequestForm from './../../components/clients/DeliveryRequestForm'
import ChatModal from '../../components/chat/ChatModal'
import OrderServicesModal from '../../components/modals/OrderServicesModal.vue'
import DocumentTemplateModal from '../../components/modals/DocumentTemplateModal.vue'
import EavModal from '../../components/modals/EavModal.vue'
import ShippingPlanContent from '../../components/amazon/ShippingPlanContent.vue'

// Helpers
import orderStatusMatrix from './../../config/OrderMatrix'

import {DeliveryOrder} from "@/apps/app/services/requests/delivery-order.service";
import {getRouteOptions} from "@/apps/app/helpers/helpers";

export default {
  name: 'ShippingPlan',
  components: {
    ActionHeader,
    CProfile: defineAsyncComponent(() => import('./../../components/clients/Profile.vue')),
    CAddress: defineAsyncComponent(() => import('../../components/clients/Address.vue')),
    DSCalculator: defineAsyncComponent(() => import('../../components/delivery-services/Calculator.vue')),
    History,
    NInteractions,
    ServicePointsMap: defineAsyncComponent(() => import('./../../components/service-points-map/ServicePointsMap.vue')),
    BaseAddress,
    DeliveryRequestForm,
    ChatModal,
    OrderServicesModal,
    DocumentTemplateModal,
    ShippingPlanContent,
    EavModal
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isSaveLoading: false,
      interactionsModal: false,
      calculatorModal: false,
      historyModal: false,
      statuses: orderStatusMatrix,
      shippingPlanStates: [
        {
          buttons: [
            { id: 'new', title: 'New', transitions: ['confirmed'], color: 'yellow-3' },
            { id: 'confirmed', title: 'Confirmed', transitions: [], color: 'positive' },
            { id: 'error', title: 'Error', transitions: ['confirmed'], color: 'red-4' }
          ]
        }
      ],
      showMap: false,
      reservesWarehouses: [],
      isManuallyLocked: false,
      hasChange: false,
      isInternational: this.$utils.getIsInternationalAddress(),
      types: ['retail', 'wholesale', 'return'],
      items: [],
      disabledDomestic: false,
      simple: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'shippingPlan',
      'cleanShippingPlan',
      'shop',
      'deliveryServices',
      'deliveryRequest',
      'cleanDeliveryRequest',
      'senders',
      'address',
      'cleanAddress',
      'isShippingPlanLocked',
      'lockedShippingPlans',
      'hasShippingPlanChange',
      'hasBaseAccess',
      'isClient'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.shippingPlan.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (extId) => {
                  this.updateShippingPlan({ extId })
                }
              }
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.shippingPlan && this.shippingPlan.id ,
        name: this.shippingPlan && this.shippingPlan.id
            ? this.$t('Order')
            : this.$t('New order')
      }
    },
    addressType () {
      let rightTab = this.simple ? 'domestic' : 'international'
      let leftTab = this.simple ? 'international' : 'domestic'

      return this.isInternational
          ? rightTab
          : leftTab
    },
    shopOwner () {
      if (!this.shippingPlan || !this.shippingPlan._embedded || !this.shippingPlan._embedded.shop || !this.shippingPlan._embedded.shop._embedded || !this.shippingPlan._embedded.shop._embedded.owner) {
        return null
      }

      return this.shippingPlan._embedded.shop._embedded.owner
    },
    calculatorError () {
      if (!this.deliveryRequest || !this.deliveryRequest._embedded) {
        return 'Shipping Request data is required!'
      }

      if (!this.shippingPlan || !this.shippingPlan._embedded) {
        return 'Order data is required!'
      }

      return ''
    },
    hasDeliveryServiceStatus () {
      return this.deliveryRequest && this.deliveryRequest.deliveryServiceStatus
    },
    hasShippingPlanErrors () {
      return this.shippingPlan && this.shippingPlan.shippingPlanErrors
    },
    headerModel () {
      if (!this.shippingPlan) {
        return []
      }
      const value = this.shippingPlanStates[0].buttons.find(({ id }) => id === this.shippingPlan.state) || { id: 'new', title: 'New' }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        editableFields: false,
        onClick: (state) => {
          if (this.shippingPlan.id) {
            const hasChange = this.hasShippingPlanChange
            this.$service.shippingPlan.save({ state }, this.shippingPlan.id)
                .then(() => {
                  this.updateShippingPlan({ state })
                  this.updateCleanShippingPlan({ state })

                  if (!hasChange) {
                    this.setHasShippingPlanChange(false)
                  }
                })
          }
        },
        value,
        options: this.shippingPlanStates
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'text-center q-pb-sm fit--mobile',
        options: [
          option
        ]
      }

      const actions = {
        section: 'Actions',
        className: 'col-12 col-sm text-center row items-center justify-center q-gutter-y-xs q-py-xs',
        options: [
          {
            id: 'delivery',
            type: 'dropdown',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            variant: 'primary',
            label: this.$t('Delivery'),
            options: [
              {
                id: 'calculator',
                type: 'button',
                icon: 'calculate',
                variant: 'light',
                label: this.$t('Calculator'),
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                onClick: () => {
                  this.calculatorModal = true
                }
              },
              {
                id: 'deliveryRequest',
                icon: 'local_shipping',
                label: this.$t('Shipping Request'),
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                onClick: this.handleOpenDeliveryRequest
              },
              {
                id: 'print',
                icon: 'print',
                label: this.$t('Print Label'),
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                onClick: this.handlePrint
              }
            ]
          },
          {
            id: 'more',
            type: 'dropdown',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            variant: 'primary',
            label: this.$t('More'),
            options: [
              {
                id: 'history',
                icon: 'history',
                disabled: !this.shippingPlan || !this.shippingPlan.id,
                label: this.$t('History'),
                onClick: () => {
                  this.historyModal = true
                }
              },
              {
                id: 'interactions',
                icon: 'phone',
                disabled: !this.shippingPlan || !this.shippingPlan.id,
                label: this.$t('Interactions'),
                onClick: () => {
                  this.interactionsModal = true
                }
              },
              {
                id: 'template',
                icon: 'receipt_long',
                disabled: !this.shippingPlan || !this.shippingPlan.id,
                label: this.$t('Documents'),
                onClick: () => {
                  this.$refs.documentModal.open(this.shippingPlan)
                }
              },
              {
                id: 'eav',
                icon: 'receipt_long',
                disabled: !this.shippingPlan || !this.shippingPlan.id || !this.shippingPlan.eav,
                label: this.$t('EAV'),
                onClick: () => {
                  const options = {
                    entityClass: this.$entities.Orderadmin_Products_Entity_Order
                  }

                  this.$refs.eavModal.open(this.shippingPlan.eav, options)
                }
              }
            ]
          },
          {
            if: !this.isClient,
            id: 'services',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            type: 'button',
            variant: 'light',
            disabled: !this.shippingPlan || !this.shippingPlan.id,
            label: this.$t('Services'),
            onClick: () => {
              const defaultData = {
                state: 'active',
                order: this.shippingPlan.id,
                warehouse: this.shippingPlan.warehouse,
                currency: this.deliveryRequest && this.deliveryRequest._embedded && this.deliveryRequest._embedded.currency
                    ? this.deliveryRequest._embedded.currency.id
                    : null
              }

              this.$refs.orderServices.open(this.shippingPlan.id, defaultData)
            }
          },
          {
            id: 'chat',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            type: 'button',
            icon: 'comments',
            hasIcon: true,
            variant: 'light',
            disabled: !this.shippingPlan || !this.shippingPlan.id,
            onClick: () => {
              const entities = [
                { entityClass: 'Orderadmin\\Integrations\\Amazon\\Entity\\FBA\\ShippingPlan', entity: this.shippingPlan.id },
                { entityClass: 'Orderadmin\\Products\\Entity\\Shop', entity: this.order._embedded.shop.id }
              ]

              this.$refs.chatModal.open(entities)
            }
          }
        ]
      }

      let shippingPlanTitle = this.$t('New Shipping Plan')

      if (this.shippingPlan.id) {
        shippingPlanTitle = `${this.$t('Shipping Plan ID')}: ${this.shippingPlan.id}`
      }
      const groups = [
        {
          section: 'BackAction',
          className: 'hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-6 col-sm-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              additionalValueClassName: 'col-6 col-sm-12 q-px-xs',
              type: 'text',
              value: shippingPlanTitle
            }
          ]
        },
        actions,
        dropdown
      ]

      return groups
    },
    // Get is fields are locked for current order state
    isDisabledFields () {
      if (!this.shippingPlan || !this.shippingPlan.id) {
        return false
      }

      if (this.shippingPlan.type === 'return' && this.shippingPlan.state === 'return') {
        return false
      }

      let currentStateOptions = this.statuses[0].buttons[0]

      for (let i = 0; i < this.statuses.length; i++) {
        const group = this.statuses[i]
        const state = group.buttons.find(btn => btn.id === (this.shippingPlan || {}).state)

        if (state) {
          currentStateOptions = state
          break
        }
      }

      return !currentStateOptions.editableField || this.isShippingPlanLocked
    }
  },
  watch: {
    cleanShippingPlan () {
      this.hasChange = false
    }
  },
  mounted () {
    this.setHasShippingPlanChange(false)
    this.loadFullShippingPlan()
    this.isDisabledDomestic()
    this.simple = this.appOptions.versionType === 'simple'
},
  unmounted () {
    this.setHasShippingPlanChange(false)

    if (this.shippingPlan && this.shippingPlan.id) {
      if (!this.lockedShippingPlans.includes(this.shippingPlan.id)) {
        this.$channels.user.publish('closeShippingPlan', this.shippingPlan.id)
      }

      if (this.isManuallyLocked) {
        this.removeLockedShippingPlan(this.shippingPlan.id)
      }
    }

    this.resetShippingPlan()
  },
  methods: {
    ...mapActions([
      'resetShippingPlan'
    ]),
    ...mapMutations([
      'updateShippingPlanSource',
      'updateShippingPlan',
      'setNewAddress',
      'setNewShippingPlan',
      'setNewDeliveryRequest',
      'updateDeliveryRequest',
      'addWarningNotification',
      'addErrorNotification',
      'setShippingPlan',
      'addLockedShippingPlan',
      'removeLockedShippingPlan',
      'updateCleanShippingPlan',
      'setHasShippingPlanChange',
      'updateDeliveryRequestEmbedded',
      'setAddress',
      'updateAddress',
      'upsertShippingPlan',
      'updateShippingPlanEmbedded',
      'upsertDeliveryRequest'
    ]),
    isDisabledDomestic () {
      const options = getRouteOptions('address')

      if (options.additionalProperties && options.additionalProperties.disabledFor) {
        const disabledFor = options.additionalProperties.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          this.disabledDomestic = true
          return
        }
      }

      return
    },
    handleItems (products) {
      this.items = products
    },
    handleEAVSubmit (eav) {
      this.updateShippingPlan({ eav })
      this.hasChange = true
    },
    loadFullShippingPlan () {
      // If no if in route then create new order
      if (!this.$route.params.id) {
        if (!this.$route.query.hasInit || !this.order) {
          this.setNewShippingPlan({ type: this.$route.params.type })
          this.setNewAddress()
          this.setNewDeliveryRequest()
          this.setHasShippingPlanChange(false)
        }

        if (!this.order._embedded.shop) {
          const query = {
            per_page: 5,
            page: 1,
            filter: [
              { type: 'in', field: 'state', values: ['active', 'blocked'] }
            ]
          }

          this.$service.shop.getAll(query)
              .then(({ items, totalItems }) => {
                if (totalItems === 1) {
                  this.updateShippingPlanEmbedded({ shop: items[0] })
                }
              })
        }

        return
      }

      return this.$service.shippingPlan.get(this.$route.params.id)
          .then(( shippingPlan ) => {

            this.setShippingPlan(shippingPlan)
            this.setHasShippingPlanChange(false)
            if(shippingPlan.raw.ShipToAddress){
              this.setAddress(shippingPlan.raw.ShipToAddress)
            }

          })
    },
    handleDRChange () {
      this.setHasShippingPlanChange(true)
    },
    handleDate (shipmentDate) {
      this.updateShippingPlan({ shipmentDate })
    },
    handleShopChange (shop) {
      if (this.shippingPlan.id && this.shippingPlan._embedded.shop && this.shippingPlan._embedded.shop.id) {
        return
      }

      this.updateShippingPlanEmbedded({ shop })
    },
    handleWarehouseChange (warehouse) {
      if (this.shippingPlan.id) {
        return
      }

      this.updateShippingPlanEmbedded({ warehouse })
    },
    // Indicate Centrifugo which order is open
    setWarehousesByReserves (reserves) {
      return this.loadWarehousesByReserves(reserves)
          .then(({ items }) => {
            this.reservesWarehouses = items
            this.setHasShippingPlanChange(false)
          })
    },
    loadWarehousesByReserves (reserves) {
      if (reserves.filter(val => val).length === 0) {
        return Promise.resolve({ items: [] })
      }

      // Extract warehouses from reserves
      const values = reserves.reduce((acc, reserve) => {
        if (reserve && !acc.includes(reserve.warehouse)) {
          acc.push(reserve.warehouse)
        }

        return acc
      }, [])

      const query = {
        per_page: values.length,
        page: 1,
        filter: [
          { type: 'in', field: 'id', values }
        ]
      }

      return this.$service.warehouse.getAll(query)
    },
    handleLocality (locality) {
      if (!locality || !locality.id) {
        return
      }

      this.handleLocalityChange(locality)
    },
    handleUnlock () {
      if (this.isShippingPlanLocked) {
        this.isManuallyLocked = false
        return this.removeLockedShippingPlan(this.shippingPlan.id)
      }

      this.isManuallyLocked = true
      return this.addLockedShippingPlan(this.shippingPlan.id)
    },
    // Change delivery request prices depend on order products
    updatePrices () {
      if (!this.deliveryRequest) {
        return
      }

      const orderPrice = this.items.reduce((acc, val) => {
        return acc + (Number(val.price) || 0) * (Number(val.count) || 1)
      }, 0)

      const update = {
        payment: this.shippingPlan.paymentState === 'not_paid'
            ? orderPrice
            : 0,
        estimatedCost: orderPrice
      }

      this.updateDeliveryRequest(update)
    },
    handleCloseMap () {
      this.showMap = false
    },
    handleOpenMap () {
      this.showMap = true
    },
    // Handle address change from map
    handleMapSubmit (data) {
      const address = { ...this.address, _embedded: { ...this.address._embedded } }
      address._embedded.locality = data.locality

      this.updateDeliveryRequestEmbedded({
        sender: data.sender,
        rate: data.rate,
        servicePoint: data.servicePoint
      })

      this.updateRecipientAddress(address)
      this.showMap = false
    },
    updateRate (rate) {
      this.updateDeliveryRequestEmbedded({ rate, deliveryService: rate.deliveryService, servicePoint: null })

      this.setHasOrderChange(true)
    },
    handleLocalityChange (locality) {
      this.updateRecipientAddress({
        ...this.address,
        _embedded: {
          ...this.address._embedded,
          locality
        }
      })
    },
    handleUpdateSender (sender) {
      this.updateDeliveryRequestEmbedded({ sender })
    },
    handleCalculatorSPChange (servicePoint) {
      this.updateDeliveryRequestEmbedded({ servicePoint })
      this.calculatorModal = false
    },
    save () {
      if (!this.shippingPlan.id) {
        const profileError = this.$utils.validate.profile(this.shippingPlan._embedded.profile)

        if (profileError) {
          this.addErrorNotification(profileError)
          return
        }

        const shippingPlanError = this.$utils.validate.order(this.shippingPlan)

        if (shippingPlanError) {
          this.addErrorNotification(shippingPlanError)
          return
        }

        const isCourier = this.deliveryRequest._embedded.rate
            ? !['service_point', 'self_service_point', 'simple'].includes(this.deliveryRequest._embedded.rate.type)
            : true

        if (this.$service.deliveryOrder.requiredAddressFields.find(x => this.address[x] || this.address._embedded[x])) {
          const addressError = this.$utils.validate.address(this.address, isCourier, !this.isInternational)

          if (addressError) {
            this.addErrorNotification(addressError)
            return
          }

          if (!this.$service.deliveryOrder.requiredDeliveryRequestFields.find(x => this.deliveryRequest[x] || this.deliveryRequest._embedded[x])) {
            this.addErrorNotification('Please fill required data for shipping request')
            return
          }
        }

        if (this.$service.deliveryOrder.requiredDeliveryRequestFields.find(x => this.deliveryRequest[x] || this.deliveryRequest._embedded[x])) {
          const deliveryRequestError = this.$utils.validate.baseDeliveryRequest(this.deliveryRequest, this.order.type === 'return')

          if (deliveryRequestError) {
            this.addErrorNotification(deliveryRequestError)
            return
          }
        }
      }
      this.isSaveLoading = true
      return this.$service.deliveryOrder.save(
          { update: this.order, clean: this.cleanOrder },
          { update: this.deliveryRequest, clean: this.cleanDeliveryRequest },
          { update: this.address, clean: this.cleanAddress },
          { update: this.$refs.orderProducts.getChanges() }
      )
          .then(({ shippingPlan, deliveryRequest, address }) => {
            this.upsertShippingPlan({ ...shippingPlan, _embedded: { ...shippingPlan._embedded, deliveryRequest } })
            this.setHasShippingPlanChange(false)

            if (deliveryRequest && deliveryRequest.id) {
              this.upsertDeliveryRequest(deliveryRequest)
            }

            if (!this.shippingPlan.id) {
              this.handleBack()
              return
            }

            this.setShippingPlan(shippingPlan)

          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    handleOpenDeliveryRequest () {
      if (!this.deliveryRequest.id) {
        this.addWarningNotification('Shipping request not exist!')
        return
      }

      this.$router.push('/outbound/shipments/entity/' + this.deliveryRequest.id)
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handlePrint () {
      this.$service.printer.generateBarcode({
        barcode: `P/O/${this.order.id}`,
        size: '88x44'
      })
    },
    updateProfile (profile) {
      this.updateOrderEmbedded({ profile })
    },
    updatePhone (phone) {
      this.updateOrderEmbedded({ phone })
    },
    updateRecipientAddress (address) {
      this.updateAddress(address)
      this.setHasOrderChange(true)
    },
    updateMap () {
      if (this.deliveryRequest._embedded.servicePoint && this.deliveryRequest._embedded.servicePoint.geo) {
        this.$refs.address.setCenter(this.deliveryRequest._embedded.servicePoint.geo)
      } else if (this.address._embedded.locality && this.address._embedded.locality.geo) {
        this.$refs.address.setCenter(this.address._embedded.locality.geo)
      }
    },
  }
}
</script>>
